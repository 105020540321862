/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export { ApiError } from './core/ApiError';
export { CancelablePromise, CancelError } from './core/CancelablePromise';
export { OpenAPI } from './core/OpenAPI';
export type { OpenAPIConfig } from './core/OpenAPI';

export type { AddEmergencyContactPayload } from './models/AddEmergencyContactPayload';
export type { AdminPairingRequestPayload } from './models/AdminPairingRequestPayload';
export type { AlertResponse } from './models/AlertResponse';
export { AlertSource } from './models/AlertSource';
export { AlertStatus } from './models/AlertStatus';
export { AlgoProfile } from './models/AlgoProfile';
export { AlibabaIotPlatformDetails } from './models/AlibabaIotPlatformDetails';
export type { APKMetaData } from './models/APKMetaData';
export { AppLogAutoLevel } from './models/AppLogAutoLevel';
export { AppLogOnDemandLevel } from './models/AppLogOnDemandLevel';
export type { AssignedResourceResponseV3 } from './models/AssignedResourceResponseV3';
export { BedExitStatusImminent } from './models/BedExitStatusImminent';
export { BedExitWallSide } from './models/BedExitWallSide';
export { BLEServerType } from './models/BLEServerType';
export type { CallLogEntry } from './models/CallLogEntry';
export { CancelFall } from './models/CancelFall';
export type { CareGiver } from './models/CareGiver';
export type { CareGiverPushToken } from './models/CareGiverPushToken';
export { CareGiverType } from './models/CareGiverType';
export type { ChannelFirmware } from './models/ChannelFirmware';
export { ConfigurationHistoryEntry } from './models/ConfigurationHistoryEntry';
export type { CreateDeviceWebhookIdPayload } from './models/CreateDeviceWebhookIdPayload';
export type { CreateRoomPayload } from './models/CreateRoomPayload';
export { DashboardConfig } from './models/DashboardConfig';
export type { DashboardDataAppEvent } from './models/DashboardDataAppEvent';
export { DashboardDoorEvent } from './models/DashboardDoorEvent';
export { DashboardEvent } from './models/DashboardEvent';
export type { DashboardEventBase } from './models/DashboardEventBase';
export type { DashboardEventHistory } from './models/DashboardEventHistory';
export { DashboardFallEvent } from './models/DashboardFallEvent';
export type { DashboardPresenceEvent } from './models/DashboardPresenceEvent';
export { DashboardPresenceEventContainer } from './models/DashboardPresenceEventContainer';
export type { DashboardRoomEvent } from './models/DashboardRoomEvent';
export { DashboardSensitiveFallEvent } from './models/DashboardSensitiveFallEvent';
export { DashboardStatusEvent } from './models/DashboardStatusEvent';
export type { DataAppCancel } from './models/DataAppCancel';
export type { DataAppConfig } from './models/DataAppConfig';
export { DataAppConfigReload } from './models/DataAppConfigReload';
export type { DataAppEvent } from './models/DataAppEvent';
export { DataAppEventType } from './models/DataAppEventType';
export { DataAppType } from './models/DataAppType';
export type { Device } from './models/Device';
export type { DeviceAdminResponse } from './models/DeviceAdminResponse';
export type { DeviceAlertSuppress } from './models/DeviceAlertSuppress';
export type { DeviceAlgoFilesResponseBody } from './models/DeviceAlgoFilesResponseBody';
export { DeviceBedExitEventImminent } from './models/DeviceBedExitEventImminent';
export type { DeviceChannel } from './models/DeviceChannel';
export type { DeviceCloudConfig } from './models/DeviceCloudConfig';
export type { DeviceCommand } from './models/DeviceCommand';
export { DeviceCommandGen2Reboot } from './models/DeviceCommandGen2Reboot';
export type { DeviceConfigActiveTemplate } from './models/DeviceConfigActiveTemplate';
export type { DeviceConfigGen2 } from './models/DeviceConfigGen2';
export type { DeviceConfigGen2AppConfig } from './models/DeviceConfigGen2AppConfig';
export type { DeviceConfigGen2Patch } from './models/DeviceConfigGen2Patch';
export type { DeviceConfigGen2WalabotConfig } from './models/DeviceConfigGen2WalabotConfig';
export type { DeviceConfigTemplate } from './models/DeviceConfigTemplate';
export type { DeviceConfigTemplatePayload } from './models/DeviceConfigTemplatePayload';
export type { DeviceConfigurationEvent } from './models/DeviceConfigurationEvent';
export { DeviceConfigurationEventType } from './models/DeviceConfigurationEventType';
export type { DeviceDataDownloadLink } from './models/DeviceDataDownloadLink';
export type { DeviceDisabled } from './models/DeviceDisabled';
export { DeviceDoorEvent } from './models/DeviceDoorEvent';
export type { DeviceEvent } from './models/DeviceEvent';
export { DeviceEventType } from './models/DeviceEventType';
export { DeviceFallEvent } from './models/DeviceFallEvent';
export type { DeviceFamConfig } from './models/DeviceFamConfig';
export type { DeviceFamEnableBulk } from './models/DeviceFamEnableBulk';
export type { DeviceFamGoLiveBulk } from './models/DeviceFamGoLiveBulk';
export type { DeviceInfo } from './models/DeviceInfo';
export { DeviceInstallInfo } from './models/DeviceInstallInfo';
export type { DeviceLocation } from './models/DeviceLocation';
export type { DeviceMonitorInfo } from './models/DeviceMonitorInfo';
export type { DeviceMonitorStatInfo } from './models/DeviceMonitorStatInfo';
export { DeviceMonitorStatus } from './models/DeviceMonitorStatus';
export type { DeviceNotFound } from './models/DeviceNotFound';
export { DeviceOutOfBedEvent } from './models/DeviceOutOfBedEvent';
export { DevicePresenceEvent } from './models/DevicePresenceEvent';
export type { DeviceRelateInfo } from './models/DeviceRelateInfo';
export type { DeviceRoomConfig } from './models/DeviceRoomConfig';
export type { DeviceRoomPresence } from './models/DeviceRoomPresence';
export { DeviceRoomPresenceState } from './models/DeviceRoomPresenceState';
export { DeviceSensitiveFallEvent } from './models/DeviceSensitiveFallEvent';
export type { DeviceState } from './models/DeviceState';
export type { DeviceStateGen2 } from './models/DeviceStateGen2';
export type { DeviceStateModel } from './models/DeviceStateModel';
export { DeviceStatus } from './models/DeviceStatus';
export type { DeviceStatusGen2 } from './models/DeviceStatusGen2';
export type { DeviceTest } from './models/DeviceTest';
export { DeviceTestStatus } from './models/DeviceTestStatus';
export { DeviceType } from './models/DeviceType';
export { DiagnosticsType } from './models/DiagnosticsType';
export { DismissAlertEvent } from './models/DismissAlertEvent';
export type { DismissAlertRequest } from './models/DismissAlertRequest';
export { DoorEvent } from './models/DoorEvent';
export { DoorEventStatus } from './models/DoorEventStatus';
export { DownloadFirmware } from './models/DownloadFirmware';
export { DryContactConfig } from './models/DryContactConfig';
export { EhrType } from './models/EhrType';
export type { EventHistory } from './models/EventHistory';
export { EventLevel } from './models/EventLevel';
export { EventPayloadType } from './models/EventPayloadType';
export type { EventsHistory } from './models/EventsHistory';
export { EventsHistoryStatus } from './models/EventsHistoryStatus';
export { ExternalDeviceEvent } from './models/ExternalDeviceEvent';
export type { FallEvent } from './models/FallEvent';
export { FallEventStatus } from './models/FallEventStatus';
export type { FallHistory } from './models/FallHistory';
export type { FallInferenceRequest } from './models/FallInferenceRequest';
export type { FallInferenceResponse } from './models/FallInferenceResponse';
export { FallInferenceResult } from './models/FallInferenceResult';
export { FallingSensitivity } from './models/FallingSensitivity';
export type { FirmwareDownloadLink } from './models/FirmwareDownloadLink';
export type { FullRoomsStatsReport } from './models/FullRoomsStatsReport';
export { GcpIotCoreDetails } from './models/GcpIotCoreDetails';
export type { GenerateLogsLinkPayload } from './models/GenerateLogsLinkPayload';
export type { GenerateLogsLinkResponse } from './models/GenerateLogsLinkResponse';
export type { generateTokenResponse } from './models/generateTokenResponse';
export type { GeolocationResponseBody } from './models/GeolocationResponseBody';
export { HistoryEventType } from './models/HistoryEventType';
export type { HomeDevice } from './models/HomeDevice';
export type { InactivityConfig } from './models/InactivityConfig';
export { Initializing } from './models/Initializing';
export { Invalid } from './models/Invalid';
export { Learning } from './models/Learning';
export { LedMode } from './models/LedMode';
export { LedPolicy } from './models/LedPolicy';
export type { LinkDevicePayload } from './models/LinkDevicePayload';
export { Locale } from './models/Locale';
export { LogLevel } from './models/LogLevel';
export type { MassPairing } from './models/MassPairing';
export { MeasurementUnits } from './models/MeasurementUnits';
export { Monitoring } from './models/Monitoring';
export { MqttDetails } from './models/MqttDetails';
export type { MqttHook } from './models/MqttHook';
export type { MqttHookBulkReqBody } from './models/MqttHookBulkReqBody';
export { NotificationTopic } from './models/NotificationTopic';
export type { NotifyNCUpdateAlertResolutionRequest } from './models/NotifyNCUpdateAlertResolutionRequest';
export type { OnGoingPairing } from './models/OnGoingPairing';
export type { OTAApkMetaData } from './models/OTAApkMetaData';
export type { Pagination } from './models/Pagination';
export type { PairedCareGiver } from './models/PairedCareGiver';
export type { PairedDevice } from './models/PairedDevice';
export type { PairingCode } from './models/PairingCode';
export type { PairingInvite } from './models/PairingInvite';
export type { PairingRecord } from './models/PairingRecord';
export type { PairingRequest } from './models/PairingRequest';
export { PairingStatus } from './models/PairingStatus';
export type { PartialCareGiver } from './models/PartialCareGiver';
export { Presence } from './models/Presence';
export type { PresenceEvent } from './models/PresenceEvent';
export { PresenceEventStatus } from './models/PresenceEventStatus';
export type { PresenceHistory } from './models/PresenceHistory';
export type { PresenceIndication } from './models/PresenceIndication';
export { PresenceTransition } from './models/PresenceTransition';
export { ProductType } from './models/ProductType';
export type { QueryAlertFilter } from './models/QueryAlertFilter';
export type { QueryDeviceFamFilter } from './models/QueryDeviceFamFilter';
export type { QueryEventsHistoryFilter } from './models/QueryEventsHistoryFilter';
export type { QueryFallInferenceFilter } from './models/QueryFallInferenceFilter';
export type { QueryTenantFilter } from './models/QueryTenantFilter';
export { Rebooting } from './models/Rebooting';
export type { RegisterDevice } from './models/RegisterDevice';
export type { RegisterDeviceInfo } from './models/RegisterDeviceInfo';
export type { RegistrationResponse } from './models/RegistrationResponse';
export { ReportEmailConfig } from './models/ReportEmailConfig';
export { ResolutionType } from './models/ResolutionType';
export type { ResourceDevice } from './models/ResourceDevice';
export type { ResourceDeviceV2 } from './models/ResourceDeviceV2';
export type { ResourceDeviceV3 } from './models/ResourceDeviceV3';
export type { ResourceResponse } from './models/ResourceResponse';
export type { ResourceResponseV2 } from './models/ResourceResponseV2';
export type { ResourceResponseV3 } from './models/ResourceResponseV3';
export type { ResourceRoom } from './models/ResourceRoom';
export type { ResourceRoomV2 } from './models/ResourceRoomV2';
export type { ResourceRoomV3 } from './models/ResourceRoomV3';
export type { ResourceSuite } from './models/ResourceSuite';
export type { ResourceSuiteV2 } from './models/ResourceSuiteV2';
export type { ResourceSuiteV3 } from './models/ResourceSuiteV3';
export { RfProfile } from './models/RfProfile';
export type { Room } from './models/Room';
export type { RoomDetails } from './models/RoomDetails';
export type { RoomDetailsResponse } from './models/RoomDetailsResponse';
export type { RoomEvent } from './models/RoomEvent';
export type { RoomStats } from './models/RoomStats';
export type { RoomStatsReport } from './models/RoomStatsReport';
export { RoomType } from './models/RoomType';
export type { SensitiveFallEvent } from './models/SensitiveFallEvent';
export { SensitiveFallEventStatus } from './models/SensitiveFallEventStatus';
export { SensorMounting } from './models/SensorMounting';
export { Silent } from './models/Silent';
export { SnrPointsPolicy } from './models/SnrPointsPolicy';
export { SoftwareUpdate } from './models/SoftwareUpdate';
export type { StatusCallbackData } from './models/StatusCallbackData';
export type { SubregionAggregateStat } from './models/SubregionAggregateStat';
export type { SubregionCompareStat } from './models/SubregionCompareStat';
export type { SubregionStat } from './models/SubregionStat';
export { SubRegionType } from './models/SubRegionType';
export type { Suite } from './models/Suite';
export type { SuiteDetails } from './models/SuiteDetails';
export type { SuiteNotFound } from './models/SuiteNotFound';
export type { SupportTicket } from './models/SupportTicket';
export { Suspend } from './models/Suspend';
export type { TargetOnGroundParams } from './models/TargetOnGroundParams';
export { TelemetryPolicy } from './models/TelemetryPolicy';
export { TelemetryTransport } from './models/TelemetryTransport';
export type { Tenant } from './models/Tenant';
export type { TenantConfig } from './models/TenantConfig';
export { Test } from './models/Test';
export type { ThresholdRegion } from './models/ThresholdRegion';
export type { TrackerSubRegion } from './models/TrackerSubRegion';
export { TrackerTargetsDebugPolicy } from './models/TrackerTargetsDebugPolicy';
export type { TwilioCallInfo } from './models/TwilioCallInfo';
export type { UnAssignedResourceResponseV3 } from './models/UnAssignedResourceResponseV3';
export type { UpdateAlertResolutionRequest } from './models/UpdateAlertResolutionRequest';
export { UpdateBaseUrl } from './models/UpdateBaseUrl';
export type { UpdateDeviceWebhookIdPayload } from './models/UpdateDeviceWebhookIdPayload';
export type { UpdateEmergencyContactPayload } from './models/UpdateEmergencyContactPayload';
export type { UpdateEventsHistory } from './models/UpdateEventsHistory';
export type { UpdateRoomFcmTokenPayload } from './models/UpdateRoomFcmTokenPayload';
export { UpdateSensitivityMap } from './models/UpdateSensitivityMap';
export { UpdateWifiCredentials } from './models/UpdateWifiCredentials';
export { UploadCoreDump } from './models/UploadCoreDump';
export { UploadDspLogs } from './models/UploadDspLogs';
export { UploadEspLogs } from './models/UploadEspLogs';
export { UploadTelemetries } from './models/UploadTelemetries';
export { Webhook } from './models/Webhook';
export type { WebhookBedExitDismissEvent } from './models/WebhookBedExitDismissEvent';
export type { WebhookBedExitEvent } from './models/WebhookBedExitEvent';
export type { WebhookFallEvent } from './models/WebhookFallEvent';
export type { WebhookOutOfBedEvent } from './models/WebhookOutOfBedEvent';
export type { WebhookPresenceEvent } from './models/WebhookPresenceEvent';
export type { WebhookSensitiveFallEvent } from './models/WebhookSensitiveFallEvent';
export type { WebhookStateEvent } from './models/WebhookStateEvent';
export type { ZoneRegion } from './models/ZoneRegion';

export { AlertAPI } from './services/AlertAPI';
export { AlgoAPI } from './services/AlgoAPI';
export { CaregiverAPI } from './services/CaregiverAPI';
export { DataAppEventAPI } from './services/DataAppEventAPI';
export { DeviceAPI } from './services/DeviceAPI';
export { DeviceAdminAPI } from './services/DeviceAdminAPI';
export { DeviceAssetsAPI } from './services/DeviceAssetsAPI';
export { DeviceCloudConfigAPI } from './services/DeviceCloudConfigAPI';
export { DeviceConfigTemplateAPI } from './services/DeviceConfigTemplateAPI';
export { DeviceFacingAPI } from './services/DeviceFacingAPI';
export { DeviceHistoryAPI } from './services/DeviceHistoryAPI';
export { DeviceLocationApiAPI } from './services/DeviceLocationApiAPI';
export { DeviceMonitorAPI } from './services/DeviceMonitorAPI';
export { EmergencyContactsAPI } from './services/EmergencyContactsAPI';
export { ExternalAuthAPI } from './services/ExternalAuthAPI';
export { FallInferenceAPI } from './services/FallInferenceAPI';
export { LongTermInactivityAPI } from './services/LongTermInactivityAPI';
export { MqttHooksAPI } from './services/MqttHooksAPI';
export { OtaAPI } from './services/OtaAPI';
export { PairingAPI } from './services/PairingAPI';
export { RoomsAPI } from './services/RoomsAPI';
export { SuitesAPI } from './services/SuitesAPI';
export { SupportAPI } from './services/SupportAPI';
export { TenantAPI } from './services/TenantAPI';
export { TenantsAPI } from './services/TenantsAPI';
export { UserApiAPI } from './services/UserApiAPI';
export { VoipAPI } from './services/VoipAPI';
export { WebhooksAPI } from './services/WebhooksAPI';
