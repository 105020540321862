/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum HistoryEventType {
    Fall = 'Fall',
    PresenceMonitor = 'PresenceMonitor',
    LowPosture = 'LowPosture',
    OutOfBed = 'OutOfBed',
    LongTermInactivity = 'LongTermInactivity',
    DeviceOffline = 'DeviceOffline',
    SensitiveFall = 'SensitiveFall',
}
