/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum SubRegionType {
    Custom = 0,
    Bed = 1,
    Door = 2,
    Toilet = 3,
}
