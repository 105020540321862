/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DeviceMonitorInfo } from '../models/DeviceMonitorInfo';
import type { DeviceMonitorStatInfo } from '../models/DeviceMonitorStatInfo';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class DeviceMonitorAPI {

    /**
     * Get Device Monitor Info
     * @param deviceIds The unique identifier of the device
     * @param suiteIds The unique identifier of the suite
     * @param roomIds The unique identifier of the room
     * @param fromTimestamp Filter results 'fromTimestamp' date in timestamp format
     * @param toTimestamp Filter results 'toTimestamp' date in timestamp format
     * @returns DeviceMonitorInfo OK
     * @throws ApiError
     */
    public static getDeviceMonitorInfo(
        deviceIds?: (string | Array<string>),
        suiteIds?: (string | Array<string>),
        roomIds?: (string | Array<string>),
        fromTimestamp?: string,
        toTimestamp?: string,
    ): CancelablePromise<Array<DeviceMonitorInfo>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/deviceMonitor/info',
            query: {
                'deviceIds': deviceIds,
                'suiteIds': suiteIds,
                'roomIds': roomIds,
                'fromTimestamp': fromTimestamp,
                'toTimestamp': toTimestamp,
            },
            errors: {
                401: `Unauthorized`,
            },
        });
    }

    /**
     * Get Device Monitor Stat Info
     * @param deviceIds The unique identifier of the device
     * @param suiteIds The unique identifier of the suite
     * @param roomIds The unique identifier of the room
     * @param fromDay Filter results 'fromDay' date in date format
     * @param toDay Filter results 'toDay' date in date format
     * @returns DeviceMonitorStatInfo OK
     * @throws ApiError
     */
    public static getDeviceMonitorStatInfo(
        deviceIds?: (string | Array<string>),
        suiteIds?: (string | Array<string>),
        roomIds?: (string | Array<string>),
        fromDay?: string,
        toDay?: string,
    ): CancelablePromise<Array<DeviceMonitorStatInfo>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/deviceMonitor/stat',
            query: {
                'deviceIds': deviceIds,
                'suiteIds': suiteIds,
                'roomIds': roomIds,
                'fromDay': fromDay,
                'toDay': toDay,
            },
            errors: {
                401: `Unauthorized`,
            },
        });
    }

}
