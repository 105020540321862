/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DeviceCloudConfig } from '../models/DeviceCloudConfig';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class DeviceCloudConfigAPI {

    /**
     * Set Device Cloud Config
     * @param deviceId
     * @param requestBody
     * @returns DeviceCloudConfig OK
     * @throws ApiError
     */
    public static updateDeviceCloudyConfig(
        deviceId: string,
        requestBody?: DeviceCloudConfig,
    ): CancelablePromise<DeviceCloudConfig> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/deviceCloudConfig/{deviceId}',
            path: {
                'deviceId': deviceId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
            },
        });
    }

    /**
     * Get Device Cloud Config
     * @param deviceId
     * @returns DeviceCloudConfig OK
     * @throws ApiError
     */
    public static getDeviceCloudyConfig(
        deviceId: string,
    ): CancelablePromise<DeviceCloudConfig> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/deviceCloudConfig/{deviceId}',
            path: {
                'deviceId': deviceId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
    }

}
