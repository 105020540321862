import {
  AfterViewInit,
  Component,
  EventEmitter,
  Inject,
  OnInit,
  Output,
} from "@angular/core";
import { AuthService, BaseComponent, UsersService } from "../ui.module";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  Validators,
} from "@angular/forms";
import { Environment, RegionInfo } from "../models";
import { takeUntil } from "rxjs/operators";
import {
  confirmPasswordValidator,
  LogErrorStateMatcher,
} from "../login/login.component";
import { FirebaseError } from "firebase/app";
import {
  FirebaseErrorCode,
  getLatestUserRegion,
  UserRegion,
} from "../auth.service";
import { getPasswordValidator } from "../utils";
import { OpenAPI } from "@walabot-mqtt-dashboard/api";

const { required, email } = Validators as ValidationErrors | null;

interface LoginForm {
  region: FormControl<string>;
  name: FormControl<string>;
  email: FormControl<string>;
  password: FormControl<string>;
  confirmPassword: FormControl<string>;
}

const passwordValidator = getPasswordValidator();

@Component({
  selector: "app-vc-platform-login",
  templateUrl: "./vc-platform-login.component.html",
  styleUrls: ["./vc-platform-login.component.css"],
})
export class VCPlatformLoginComponent
  extends BaseComponent
  implements OnInit, AfterViewInit
{
  @Output() loggedIn = new EventEmitter();
  isDashboardUser: boolean;
  formGroup: FormGroup<LoginForm>;
  matcher = new LogErrorStateMatcher();
  register = false;
  inForgotPassword = false;
  inProgress: boolean;
  showLogo;
  showPassword = false;
  regionList: RegionInfo[] = [
    {
      value: "us",
      name: $localize`:@@us-region:US Region`,
    },
    {
      value: "eu",
      name: $localize`:@@eu-region:EU Region`,
    },
  ];
  multiRegion: boolean;
  insideIFrame = window.frameElement;
  regionDisable = false;

  constructor(
    private authService: AuthService,
    private usersService: UsersService,
    private fb: FormBuilder,
    @Inject("environment") private environment: { [key: string]: Environment }
  ) {
    super();
    this.multiRegion = environment.multiRegion as boolean;
    this.formGroup = this.fb.group(
      {
        region: ["", this.multiRegion ? [required] : []],
        name: ["", [required]],
        email: ["", [required, email]],
        password: ["", [required]],
        confirmPassword: ["", [required, getPasswordValidator()]],
      },
      { validators: confirmPasswordValidator() }
    );
    this.showLogo = environment.showLogo;
  }

  ngOnInit() {
    this.register = false;

    this.formGroup.controls.password.valueChanges
      .pipe(takeUntil(this.ngUnsubsrcibe))
      .subscribe(() => {
        console.log("Password value changed");
        const errors = this.formGroup.controls.email.errors
          ? this.formGroup.controls.email.errors
          : {};
        delete errors.incorrectEmailOrPassword;
        if (Object.keys(errors).length === 0) {
          this.formGroup.controls.email.setErrors(null);
        } else {
          this.formGroup.controls.email.setErrors(errors);
        }
      });
    this.formGroup.controls.email.valueChanges
      .pipe(takeUntil(this.ngUnsubsrcibe))
      .subscribe((value) => {
        console.log("Email value changed");
        value && this.getRegionFromLocalStorage(value.toLowerCase());

        const errors = this.formGroup.controls.password.errors
          ? this.formGroup.controls.password.errors
          : {};
        delete errors.incorrectEmailOrPassword;
        if (Object.keys(errors).length === 0) {
          this.formGroup.controls.password.setErrors(null);
        } else {
          this.formGroup.controls.password.setErrors(errors);
        }
      });
  }

  ngAfterViewInit() {
    this.regionDisable = false;
    if (this.multiRegion) {
      setTimeout(() => {
        if (this.insideIFrame) {
          // get the window admin user's current region, and disable the region select
          const currentUserRegion = getLatestUserRegion();
          this.formGroup.controls.region.setValue(currentUserRegion);
          this.regionDisable = true;
        }
      }, 700);
    }
  }

  getLoginTitle() {
    return this.inForgotPassword
      ? $localize`:@@forgot-password-title:Forgot Password`
      : this.register
      ? $localize`:@@sign-up:Sign up`
      : $localize`:@@sign-in:Sign in`;
  }

  getPasswordHintText() {
    return !this.register
      ? $localize`:@@enter-your-password:Enter your password`
      : $localize`:@@create-your-password:Create your password`;
  }

  getRegisterButtonText() {
    return this.register
      ? $localize`:@@sign-up:Sign up`
      : $localize`:@@sign-in:Sign in`;
  }

  getOpenRegisterText() {
    return this.inForgotPassword
      ? $localize`:@@back-to:Back to`
      : this.register
      ? $localize`:@@already-have-an-account:Already have an account?`
      : $localize`:@@dont-have-an-account:Don't have an account?`;
  }
  getOpenRegisterButtonText() {
    return this.inForgotPassword || this.register
      ? $localize`:@@sign-in:Sign in`
      : $localize`:@@sign-up:Sign up`;
  }

  getRegionFromLocalStorage(email: string) {
    if (this.insideIFrame) return;
    const region = localStorage.getItem(`user-${email}-latest-region`);
    if (region && this.multiRegion) {
      this.formGroup.controls.region.setValue(region);
    }
  }

  setAPiBaseBeforeLogin(region: string) {
    OpenAPI.BASE = this.environment.apiUrl[region] as string;
  }
  setRegionBeforeLogin(region: string, email: string) {
    console.log("multiRegion email:", email, region);
    const currentUser: UserRegion = { region, email };
    localStorage.setItem(`user-${email}-latest-region`, region);
    localStorage.setItem("latest-user-region", JSON.stringify(currentUser));
  }

  login() {
    let promise: Promise<any>;
    this.inProgress = true;
    const email = this.formGroup.controls.email.value;
    const password = this.formGroup.controls.password.value;
    const region = this.formGroup.controls.region.value;
    if (this.multiRegion) {
      this.setAPiBaseBeforeLogin(region);
      this.setRegionBeforeLogin(region, email);
    }
    if (this.register) {
      const name = this.formGroup.controls.name.value;
      promise = this.usersService.createUser(name, email, password);
    } else {
      promise = this.authService.loginWithEmail(email, password);
    }
    promise
      .then(() => {
        this.loggedIn.emit();
      })
      .catch((err: FirebaseError) => {
        this.inProgress = false;
        console.warn(err);
        if (
          this.register &&
          err.code === FirebaseErrorCode.AUTH_EMAIL_ALREADY_IN_USE
        ) {
          this.formGroup.controls.email.setErrors({
            emailAddressIsAlreadyTaken: true,
          });
        } else {
          this.formGroup.controls.email.setErrors({
            incorrectEmailOrPassword: true,
          });
          this.formGroup.controls.password.setErrors({
            incorrectEmailOrPassword: true,
          });
        }
      });
  }

  getRegionErrorMessage() {
    return this.formGroup.controls.region.hasError("required")
      ? $localize`:@@you-must-select-value:You must select a value`
      : "";
  }

  getEmailErrorMessage() {
    return this.formGroup.controls.email.hasError("required")
      ? $localize`:@@you-must-enter-value:You must enter a value`
      : this.formGroup.controls.email.hasError("email")
      ? $localize`:@@not-a-valid-email:Not a valid email`
      : this.formGroup.controls.email.hasError("incorrectEmailOrPassword")
      ? $localize`:@@incorrect-email-or-password:Incorrect email or password`
      : this.formGroup.controls.email.hasError("emailAddressIsAlreadyTaken")
      ? $localize`:@@email-address-is-already-taken:Email address is already taken`
      : "";
  }

  getPasswordErrorMessage() {
    return this.formGroup.controls.password.hasError("required")
      ? $localize`:@@you-must-enter-value:You must enter a value`
      : this.formGroup.controls.password.hasError("incorrectEmailOrPassword")
      ? $localize`:@@incorrect-email-or-password:Incorrect email or password`
      : "";
  }

  getConfirmPasswordErrorMessage() {
    return this.formGroup.controls.confirmPassword.hasError("required")
      ? $localize`:@@you-must-enter-value:You must enter a value`
      : this.formGroup.hasError("confirmPassword")
      ? `Passwords don't match`
      : this.formGroup.controls.confirmPassword.hasError("pattern")
      ? $localize`:@@password-requirements-tooltip:Password must consist of a minimum of 8 characters and contain uppercase, lowercase, digits and non-alphanumeric characters (e.g., !, $, #, %)`
      : "";
  }

  getNameErrorMessage() {
    return this.formGroup.controls.name.hasError("required")
      ? "You must enter a name"
      : "";
  }

  openRegister() {
    if (this.inForgotPassword) {
      this.inForgotPassword = !this.inForgotPassword;
    } else {
      this.register = !this.register;
    }

    this.formGroup.controls.email.setErrors({
      incorrectEmailOrPassword: false,
    });
    this.formGroup.controls.password.setErrors({
      pattern: false,
      incorrectEmailOrPassword: false,
    });
    if (this.register) {
      this.formGroup.controls.password.setValidators(passwordValidator);
    } else {
      this.formGroup.controls.password.removeValidators(passwordValidator);
    }
    this.formGroup.controls.password.updateValueAndValidity();
  }

  isValid() {
    if (this.register) {
      return this.formGroup.valid;
    } else {
      return (
        (!this.multiRegion || this.formGroup.controls.region.valid) &&
        this.formGroup.controls.email.valid &&
        this.formGroup.controls.password.valid
      );
    }
  }

  forgotPassword() {
    this.inForgotPassword = true;
  }
}
