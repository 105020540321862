/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum DataAppType {
    notset = 'notset',
    PresenceMonitor = 'PresenceMonitor',
    LowPosture = 'LowPosture',
    OutOfBed = 'OutOfBed',
}
