/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum EventLevel {
    NoNotification = 'NoNotification',
    Info = 'Info',
    Warning = 'Warning',
    Alarm = 'Alarm',
}
