import {
  BedExitStatusImminent,
  DeviceStateGen2,
  DeviceStateModel,
  DeviceStatus,
  FallEvent,
  PairedDevice,
  RoomType,
  SensitiveFallEvent,
} from "@walabot-mqtt-dashboard/api";

export type Environment =
  | string
  | number
  | boolean
  | { [x: string]: Environment }
  | Array<Environment>;

export interface SuiteBase {
  name: string;
  id: string;
  alert: SuiteAlert;
}

export interface SuiteDetails extends SuiteBase {
  rooms: {
    [roomId: string]: RoomDetails;
  };
}

export interface StatusSuiteDetails extends SuiteBase {
  room: DeviceRoomDetails;
}

export interface DeviceRoomDetails extends RoomBase {
  device: DeviceStatusDetails;
}

export interface RoomBase {
  name: string;
  id: string;
  type: RoomType;
}

export interface RoomDetails extends RoomBase {
  devices: { [deviceId: string]: DeviceStatusDetails };
}

export interface DeviceStatusDetails {
  deviceId: string;
  roomType: RoomType;
  roomId: string;
  isPresenceDetected: boolean;
  presenceRegions: { [region: number]: number };
  isFallActive: boolean;
  isBedExitActive: boolean;
  deviceStatus: DeviceStatus | DeviceOfflineStatus;
  pairedDeviceDetails?: PairedDevice;
  deviceWifiState?: { [key: string]: number | string };
}

export enum DeviceOfflineStatus {
  DISCONNECTED = "disconnected",
}

export interface DeviceState extends Omit<DeviceStateGen2, "status"> {
  status: DeviceStatus | DeviceOfflineStatus;
}

export interface SuiteAlert {
  isFallActive: boolean;
  isBedExitActive: boolean;
  isPresented: boolean;
}

export interface BedExitEventImminent {
  bedExitOutput: BedExitStatusImminent;
  deviceTime?: number;
  deviceState?: DeviceStateModel;
}

export interface MessageData {
  data?: {
    [key: string]: string;
  };
}

export type MockableEvent = {
  isMock?: boolean;
  isReal?: boolean;
};

export interface MockableFallEvent extends FallEvent, MockableEvent {}
export interface MockableSensitiveFallEvent
  extends SensitiveFallEvent,
    MockableEvent {}

export type tz = (timezone: string) => { format(format: string): string };
export interface moment {
  tz: { guess(): string } & tz;
}

export type shouldFallNotificationAppear = (
  event: FallEvent | SensitiveFallEvent
) => boolean;

export interface TrackerTarget {
  xPosCm: number;
  yPosCm: number;
  zPosCm: number;
  amplitude: number;
  id: number;
  posture?: number;
}

export interface TrackerTarget2D {
  x: number;
  y: number;
  image: string;
}

export interface RegionInfo {
  value: string;
  name: string;
}
