/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum EhrType {
    PCC = 'PCC',
    Yardi = 'Yardi',
    PCS = 'PCS',
    Unity = 'Unity',
}
