/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { InactivityConfig } from '../models/InactivityConfig';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class LongTermInactivityAPI {

    /**
     * Set Suite Long Term Inactivity Config
     * @param suiteId
     * @param requestBody
     * @returns InactivityConfig OK
     * @throws ApiError
     */
    public static updateInactivityConfig(
        suiteId: string,
        requestBody?: InactivityConfig,
    ): CancelablePromise<InactivityConfig> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/longTermInactivity/config/{suiteId}',
            path: {
                'suiteId': suiteId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
            },
        });
    }

    /**
     * Get Suite Long Term Inactivity Config
     * @param suiteId
     * @returns InactivityConfig OK
     * @throws ApiError
     */
    public static getInactivityConfig(
        suiteId: string,
    ): CancelablePromise<InactivityConfig> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/longTermInactivity/config/{suiteId}',
            path: {
                'suiteId': suiteId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
    }

}
