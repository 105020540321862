/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum DataAppEventType {
    notset = 'notset',
    alarm = 'alarm',
    stat = 'stat',
    both = 'both',
}
