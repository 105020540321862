<!--The content below is only a placeholder and can be replaced.-->
<div class="root-div"
     [class.logged-in]="isLoggedIn !== null">
  <div *ngIf="isLoggedIn === null" class="full-screen-layout">
    <mat-spinner class="content"></mat-spinner>
  </div>
  <div
          *ngIf="isLoggedIn === true && hasDevices === false && !inAdminMode()"
          class="full-screen-layout"
  >
    <div class="content" style="display: flex; flex-direction: column">
      <span i18n="@@paired-devices-empty-state"
      >It seems you don't have any paired devices</span
      >
      <button
              mat-raised-button
              style="
          margin-top: 2em;
          width: fit-content;
          margin-left: auto;
          margin-right: auto;
        "
              (click)="logout()"
              i18n="@@logout"
      >
        Logout
      </button>
    </div>
  </div>
  <app-vc-platform-login *ngIf="isLoggedIn === false" (loggedIn)="afterLogin()"></app-vc-platform-login>
  <div
          *ngIf="isLoggedIn === true && (hasDevices === true || inAdminMode())"
          class="container"
          [class.is-mobile]="mobileQuery.matches"
  >
    <mat-toolbar *ngIf="!insideIFrame" color="primary" class="toolbar">
      <div style="display: flex; align-items: stretch;">
        <div class="logo">
          <object *ngIf="showLogo" data="../assets/logo.svg"></object>
          <span *ngIf="isSandbox" class="sandbox-label">sandbox</span>
        </div>
      </div>
      <div class="menu">
        <div class="nav-row" routerLinkActive="active-link">
          <nav>
            <a routerLink="{{basePath}}/alerts">
              <span i18n="@@alerts">Alerts</span><span *ngIf="deviceService.alerts.length > 0" class="alerts-number">{{deviceService.alerts.length}}</span>
            </a>
          </nav>
        </div>
        <div class="nav-row" routerLinkActive="active-link">
          <nav>
            <a routerLink="{{basePath}}/history" *appVar="eventBus.unresolvedAlertsCount | async as unresolvedAlertsCount">
              <span i18n="@@history">History</span>
              <span class="history-number" *ngIf="unresolvedAlertsCount > 0">{{unresolvedAlertsCount || 0}}</span>
            </a>
          </nav>
        </div>
        <div *ngIf="inAdminMode()" class="nav-row" routerLinkActive="active-link">
          <nav>
            <a routerLink="{{basePath}}/insights">
              <span i18n="@@insights">Insights</span></a>
          </nav>
        </div>
      </div>
      <button
              *ngIf="user"
              [matMenuTriggerFor]="menu"
              style="
          display: flex;
          flex-direction: row;
          align-content: center;
          align-items: center;
          background: none;
          border: none;
        "
      >
        <mat-spinner *ngIf="userUpdating; else userImage" class="user-image" [diameter]="30"></mat-spinner>
        <ng-template #userImage>
          <img [src]="userImageUrl" class="user-image" />
        </ng-template>
        <div
                style="
            display: flex;
            flex-direction: column;
            height: 100%;
            margin-left: 0.25em;
          "
        >
          <span class="user-name">{{ environment.presentUserNameAndEmail ? getUserNameAndEmail(user).displayName : demoUser }}</span>
          <span *ngIf="environment.presentUserNameAndEmail" class="user-email">{{ getUserNameAndEmail(user).email }}</span>
        </div>
        <img
                src="../assets/icons/dropdown_arrow.svg"
                style="margin-left: 1em"
        />
      </button>
      <mat-menu #menu="matMenu" xPosition="before">
        <button mat-menu-item data-cy="logout-btn" (click)="logout()" i18n="@@logout">Logout</button>
        <span class="version">v{{ appVersion }}</span><br>
        <span *ngIf="environment.multiRegion" class="cloud-region">{{ latestUserRegion | uppercase }} <ng-container i18n="@@cloud-region">Cloud Region</ng-container></span>
      </mat-menu>
    </mat-toolbar>
    <!-- <div *ngIf="currentUser"> -->
    <mat-sidenav-container
            class="sidenav-container"
            [style.marginTop.px]="mobileQuery.matches ? 56 : 0"
    >
      <mat-sidenav-content *ngIf="!insideIFrame || !isLoggedIn">
        <ng-container *ngIf="userTabsPresented; else elseBlock">
          <mat-tab-group [preserveContent]="true"
                         [(selectedIndex)]="activeUserIFrameIndex"
                         animationDuration="0"
                         (selectedIndexChange)="onUserTabChanged()">
            <mat-tab *ngFor="let iFrameElement of userIFrames">
              <ng-template mat-tab-label>
                {{iFrameElement.email}}
                <button *ngIf="!iFrameElement.isAdmin" mat-icon-button class="close-tab-icon" (click)="removeUserIFrame(iFrameElement)">
                  <mat-icon>close</mat-icon>
                </button>
              </ng-template>
              <div *ngIf="user.email !== iFrameElement.email && iFrameElement.iFrameReady" style="width: 100%; height: 100%; display: flex; align-items: center; justify-content: center;">
                <mat-spinner class="content"></mat-spinner>
              </div>
              <div [@routeAnimations]="o && o.activatedRouteData && o.activatedRouteData['title']" class="router-link-container">
                <router-outlet *ngIf="user.email === iFrameElement.email && iFrameElement.iFrameReady" #o="outlet"></router-outlet>
              </div>
              <iframe #iframe [src]="origin"
                      [attr.data-id]="iFrameElement.uuid"
                      [ngStyle]="{
                        display: iFrameElement.iFrameReady ? 'none' : 'block'
                      }"
                      class="admin-overflow-iframe"></iframe>
            </mat-tab>
            <mat-tab labelClass="add-tab">
              <ng-template mat-tab-label>
                <button mat-icon-button (click)="addUserIFrame($event)">
                  <mat-icon>add</mat-icon>
                </button>
              </ng-template>
            </mat-tab>
          </mat-tab-group>
        </ng-container>
        <ng-template #elseBlock>
          <div [@routeAnimations]="o && o.activatedRouteData && o.activatedRouteData['title']" class="router-link-container">
            <router-outlet #o="outlet"></router-outlet>
          </div>
        </ng-template>
      </mat-sidenav-content>
    </mat-sidenav-container>
  </div>
</div>
