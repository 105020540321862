<div style="width: 100%; height: 100%; padding: 1.5em; box-sizing: border-box;">
  <div style="height: 100%;" class="mat-elevation-z8">
    <div style="display: flex; flex-direction: row; height: 100%">
      <div class="side-menu">
        <div class="search-layout" matAutocompleteOrigin #origin="matAutocompleteOrigin">
          <input
            class="search-input"
            placeholder="Search"
            i18n-placeholder="@@search"
            matInput
            [formControl]="searchBar"
            [matAutocomplete]="auto"
            [matAutocompleteConnectedTo]="origin"
          />
          <button
            class="clear-search"
            mat-button
            *ngIf="searchBar.value?.length"
            matSuffix
            mat-icon-button
            aria-label="Clear"
            (click)="searchBar.setValue('')"
          >
            <mat-icon>close</mat-icon>
          </button>
          <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" (optionSelected)="optionSelected($event)">
            <mat-option
              *ngFor="let device of filteredSearchUnassignedDevices | async"
              (click)="unassignedDeviceSelected(device)"
              class="search-layout-option"
              [value]="device.filteredBy === 'id' ? device.id : device.androidSerial"
            >
              <div>{{ device.androidSerial }} | {{ device.name }}</div>
              <mat-hint>{{ device.id }}</mat-hint>
            </mat-option>
            <mat-option
              *ngFor="let device of filteredSearchOptions | async"
              (click)="deviceSelected(device)"
              class="search-layout-option"
              [value]="device.filteredBy === 'id' ? device.deviceId : device.androidSerial"
            >
              <div>{{ device.androidSerial }} | {{ convertRoomTypeNumberToName(device.type) }}</div>
              <mat-hint>{{ device.deviceId }}</mat-hint>
            </mat-option>
            <mat-option
              *ngFor="let room of filteredSearchRoom | async"
              (click)="suiteSelected(room.suiteId)"
              class="search-layout-option"
              [value]="room.name"
            >
              <div>{{ room.name }}</div>
            </mat-option>
          </mat-autocomplete>
          <span class="cdk-visually-hidden" #translatedTooltipMessageAddSuite i18n="@@add-suite">Add Suite</span>
          <span class="cdk-visually-hidden" #translatedTooltipMessageExport i18n="@@export-devices-csv">Export Devices CSV</span>
          <img src="../assets/icons/add-icon.svg" data-cy="add-new-suite" class="plus-icon" (click)="addSuite()"
               [matTooltip]="translatedTooltipMessageAddSuite.innerHTML"
               matTooltipPosition="above"/>
          <button mat-icon-button (click)="exportDevices()"
                  [matTooltip]="translatedTooltipMessageExport.innerHTML"
                  matTooltipPosition="above" style="width: 30px; text-align: left;">
            <span class="material-icons export-devices-button">download</span>
          </button>
        </div>
        <div style="overflow-y: auto;overflow-x: inherit;height: 100%;" cdk-virtual-scroll-viewport>
          <mat-accordion displayMode="flat" togglePosition="before" class="devices-cdkDropListGroup" cdkDropListGroup>
            <div id="unassigned-devices-Panel" cdkDropList cdkDropListSortingDisabledb [cdkDropListData]="unassignedDevicesToArray">
              <mat-expansion-panel
                *ngIf="unassignedDevices.size > 0"
                class="mat-elevation-z0 unassigned-devices-panel"
                hideToggle="true"
                #panelUnassigned
              >
                <mat-expansion-panel-header style="position: relative">
                  <mat-panel-title style="height: unset" class="unassigned-devices-panel-title">
                    <mat-icon *ngIf="!panelUnassigned.expanded" style="direction: inherit;">
                      <img src="../assets/icons/arrow.svg" class="arrow-icon" />
                    </mat-icon>
                    <mat-icon *ngIf="panelUnassigned.expanded" style="direction: inherit;">
                      <img src="../assets/icons/arrow.svg" />
                    </mat-icon>
                    <span>{{ getTotalNumberOfUnassignedDevicesText() }}</span>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <div
                  *ngFor="let device of unassignedDevices | keyvalue"
                  class="device-item unassigned-devices-item"
                  style="position: relative; height: 46px"
                  [class.selected]="isSelected(device.value)"
                  [class.target]="device.value.selected"
                  (mouseover)="showMenu = true"
                  (click)="deviceSelected({deviceId: device.value.id, type: device.value.roomType, roomId: null})"
                  cdkDrag
                  cdkDragBoundary=".devices-cdkDropListGroup"
                >
                  <!-- <svg class="status-icon"><use [attr.xlink:href]="getDeviceIcon(device.value.roomType)"></use></svg> -->
                  <span style="vertical-align: middle;margin-left: 0.25em;padding-left: 16px;">
                    {{ device.value.androidSerial}}<ng-container *ngIf="device.value.name"> | {{ device.value.name }}</ng-container>
                  </span>
                  <div class="grippy" [matMenuTriggerFor]="menu" (click)="$event.stopPropagation()" cdkDragHandle>
                    <mat-menu #menu="matMenu" xPosition="after">
                      <button data-cy="assign-to-suite" mat-menu-item (click)="assignToSuite(device.value)" i18n="@@assign-to-suite">
                        Assign to suite
                      </button>
                      <button mat-menu-item (click)="unpair(device.value.id)" i18n="@@unpair">
                        Unpair
                      </button>
                    </mat-menu>
                  </div>
                </div>
              </mat-expansion-panel>
            </div>
            <div
              *ngFor="let suiteByIdElement of suiteInfoBySuiteId | keyvalue: roomInfoOrder; let first = first"
              [attr.data-suite-id]="suiteByIdElement.value.suite.id" cdkDropList
              cdkDropListDisabled
              (cdkDropListDropped)="assignedDevicesDropped($event)"
            >
              <ng-container *ngIf="!selectedSuiteId && !selectedDevice && first">
                <ng-container *appVar="suiteSelected(suiteId || suiteByIdElement.value.suite.id)"></ng-container>
              </ng-container>
              <mat-expansion-panel
                class="mat-elevation-z0 rooms-panel"
                hideToggle="true"
                [expanded]="selectedSuiteId === suiteByIdElement.value.suite.id"
                (opened) = "suiteSelected(suiteByIdElement.value.suite.id)"
                #panel
                [attr.id]="'suite-panel-' + suiteByIdElement.value.suite.id"
              >

                <!-- [cdkDropListEnterPredicate]= "noReturnPredicate" -->
                <mat-expansion-panel-header style="position: relative">
                  <mat-panel-title [attr.data-suite-id]="suiteByIdElement.value.suite.id">
                    <mat-icon *ngIf="!panel.expanded" style="direction: inherit;">
                      <img src="../assets/icons/arrow.svg" class="arrow-icon" />
                    </mat-icon>
                    <mat-icon *ngIf="panel.expanded" style="direction: inherit;">
                      <img src="../assets/icons/arrow.svg" />
                    </mat-icon>
                    <span class="suite-name" [matTooltip]="suiteByIdElement.value.suite.name" matTooltipPosition="above">{{ suiteByIdElement.value.suite.name }}</span>
                    <span class="cdk-visually-hidden" #translatedTooltipMessageNoDeviceAssigned i18n="@@no-devices-assigned">
                      No devices assigned
                    </span>
                    <span *ngIf="getDevicesOfSuite(suiteByIdElement.value).length === 0" class="material-icons material-symbols-outlined no-devices-assigned-icons" [matTooltip]="translatedTooltipMessageNoDeviceAssigned.innerHTML" matTooltipPosition="right">
                      error
                    </span>
                    <a *ngIf="getDevicesOfSuite(suiteByIdElement.value).length" class="view-suite" (click)="suiteSelected(suiteByIdElement.value.suite.id); $event.stopPropagation(); $event.stopImmediatePropagation()"><ng-container i18n="@@overview">Overview</ng-container> &gt;</a>
                  </mat-panel-title>
                  <img
                    *ngIf="unassignedDevices.size > 0"
                    src="../assets/icons/add-icon.svg"
                    class="add-device"
                    (click)="addDeviceToSuite($event, suiteByIdElement.value.suite)"
                  />
                  <div
                    class="grippy"
                    [matMenuTriggerFor]="roomMenu"
                    (click)="$event.stopPropagation()"
                    [attr.data-cy]="suiteByIdElement.value.suite.id"
                  >
                    <mat-menu #roomMenu="matMenu" xPosition="after">
                      <button mat-menu-item (click)="editSuite(suiteByIdElement.value.suite)" i18n="@@edit" data-cy="edit-room">
                        Edit
                      </button>
                      <button mat-menu-item (click)="deleteSuite(suiteByIdElement.value.suite)" i18n="@@delete" data-cy="delete-room">
                        Delete
                      </button>
                    </mat-menu>
                  </div>
                </mat-expansion-panel-header>
                <ng-container *ngFor="let roomElement of suiteByIdElement.value.rooms">
                  <div
                    *ngFor="let device of roomElement.devices | keyvalue"
                    class="device-item device-into-room"
                    [attr.data-device-id]="device.value.deviceId"
                    style="position: relative"
                    (click)="deviceSelected(device.value)"
                    [class.selected]="isSelected(device.value)"
                    (mouseover)="showMenu = true"
                  >
                    <svg class="status-icon">
                      <use [attr.xlink:href]="getDeviceIcon(roomElement.room.type ?? device.value.type)"></use>
                    </svg>
                    <span class="room-name">{{ roomElement.room.name }}</span>
                    <div class="grippy" [matMenuTriggerFor]="menu" (click)="$event.stopPropagation()">
                      <mat-menu #menu="matMenu" xPosition="after">
                        <button mat-menu-item (click)="moveDevice(roomElement.room, selectedSuiteId)" i18n="@@move">
                          Move
                        </button>
                        <button data-cy="delete-device" mat-menu-item (click)="deleteDevice(device.value, roomElement.room, suiteByIdElement.value.suite)" i18n="@@delete">
                          Delete
                        </button>
                        <!-- <button mat-menu-item (click)="openAdvanced(device.value.deviceId)">Advanced</button> -->
                      </mat-menu>
                    </div>
                  </div>
                </ng-container>
              </mat-expansion-panel>
            </div>
          </mat-accordion>
        </div>
      </div>
      <div style="width: 80%; height: 100%">
        <div *ngIf="(!selectedSuiteId || !suiteInfoBySuiteId.get(selectedSuiteId)) && !selectedDevice" class="settings-placeholder">
          <span i18n="@@please-select-suite">Please select a suite</span>
        </div>
        <app-suite *ngIf="selectedSuiteId && !selectedDevice"
                   [suite]="suiteInfoBySuiteId.get(selectedSuiteId)"
                   (configureDevice)="deviceSelected($event)"
                   (moveDevice)="moveDevice($event, selectedSuiteId)"
                   (deleteDevice)="deleteDevice($event.device, $event.room, suiteInfoBySuiteId.get(selectedSuiteId).suite)"></app-suite>
        <app-device-settings *ngIf="selectedDevice" [validateRoomName]="getRoomNameValidatorInCurrentSuite.bind(this)"
                             (back)="selectedDevice = null" (assign)="assignToSuite(unassignedDevices.get(selectedDevice.deviceId))"></app-device-settings>
      </div>
    </div>
  </div>
</div>
