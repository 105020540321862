/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type DataAppConfigReload = {
    /**
     * Data App Config Reload
     */
    type: DataAppConfigReload.type;
};

export namespace DataAppConfigReload {

    /**
     * Data App Config Reload
     */
    export enum type {
        DataAppConfigReload = 'DataAppConfigReload',
    }


}

