/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum DeviceMonitorStatus {
    MONITORING = 'monitoring',
    LEARNING = 'learning',
    TEST = 'test',
    SILENT = 'silent',
    SOFTWARE_UPDATE = 'software update',
    REBOOTING = 'rebooting',
    INITIALIZING = 'initializing',
    SUSPEND = 'suspend',
    OFFLINE = 'offline',
}
